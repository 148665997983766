import React, { useEffect, useContext, useState } from 'react';
import { isMobile } from "react-device-detect";

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { AppContext } from '../../state/context';
import FilterListIcon from '@material-ui/icons/FilterList';
import "./FilterStyle.css";

export default function Filters() {

    const DISABLEDTAB = false;
    const context = useContext(AppContext);
    const [personValue, setPersonValue] = useState(0);
    const [activityValue, setActivityValue] = useState(0);
    const [dateValue, setDateValue] = useState<any>(DISABLEDTAB);
    const [visible, setVisible] = useState(false);
    const [prevSelectedPerson, setPrevSelectedPerson] = useState(context.user.value.fullName);

    useEffect(() => {
        if (context.activePerson.value === "all") {
            setPersonValue(1);
            // if (dateValue === DISABLEDTAB) {
            //     setDateValue(0);
            //     setLast30Days();
            // }
        } else if (context.activePerson.value === context.user.value.fullName) {
            setPersonValue(0);
        }
        else {
            setPrevSelectedPerson(context.activePerson.value);
            setPersonValue(2);
        }
    }, [context.activePerson.value]);

    const handlePersonChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setPersonValue(newValue);
    };

    const handleActivityChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActivityValue(newValue);
    };

    const handleDateChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setDateValue(newValue);
    };

    function setCurrentWeek() {
        if (dateValue === 2) {
            removeDateQuery();
            setDateValue(DISABLEDTAB);
        }
        else {
            var curr = new Date
                , first = curr.getDate() - curr.getDay() + 1
                , firstday = new Date(curr.setDate(first))
                , lastday = new Date(curr.setDate(firstday.getDate() + 7));

            setDateQuery(createDateQuery(firstday, lastday));
        }
    }

    function setPreviousWeek() {
        if (dateValue === 1) {
            removeDateQuery();
            setDateValue(DISABLEDTAB);
        }
        else {

            var beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
                , day = beforeOneWeek.getDay()
                , diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
                , lastMonday = new Date(beforeOneWeek.setDate(diffToMonday))
                , thisMonday = new Date(beforeOneWeek.setDate(diffToMonday + 7));

            setDateQuery(createDateQuery(lastMonday, thisMonday));
        }
    }

    function setLast30Days(override: boolean) {
        if (dateValue === 0 && !override) {
            removeDateQuery();
            setDateValue(DISABLEDTAB);
        }
        else {
            var firstday = new Date;
            var tomorrow = new Date;
            firstday.setDate(firstday.getDate() - 30);
            tomorrow.setDate(new Date().getDate() + 1);

            setDateQuery(createDateQuery(firstday, tomorrow));
        }
    }

    function createDateQuery(first: Date, last: Date) {
        var dateDefQuery = "(start_time > date'" + `${first.getFullYear()}-${first.getMonth() + 1}-${first.getDate()}` + "') and (start_time < date'" + `${last.getFullYear()}-${last.getMonth() + 1}-${last.getDate()}` + "')";
        return dateDefQuery
    }

    function setDateQuery(query: string) {
        context.dateDefQuery.set(query);
    }

    function removeDateQuery() {
        context.dateDefQuery.set(null);
    }

    const geodataStyle = {
        // width: '10px',
        height: '40px'
    } as React.CSSProperties;

    return (
        <div className="ml-auto">
            <div className="cursor-pointer pr-8" onClick={() => setVisible(!visible)}>
                <FilterListIcon
                    className="mr-2"
                />
                Filter
            </div>
            {visible &&
                <div className={`flex flex-col filter-container${isMobile ? "-mobile" : ""}`}>
                    <Paper square>
                        <Tabs
                            value={personValue}
                            onChange={handlePersonChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon tabs example"
                        >
                            <Tab label="Bare meg" icon={<PersonIcon />} aria-label="winter" onClick={() =>
                                context.activePerson.set(context.user.value.fullName)
                            } />
                            <Tab label="Hele Geodata" icon={<img src="https://sftp.geodata.no/images/logo/geodata-logo-v2.png" style={geodataStyle} />} aria-label="bike" onClick={() => {
                                setLast30Days(true);
                                setDateValue(0);
                                context.activePerson.set("all");
                            }
                            } />
                            <Tab label={prevSelectedPerson} icon={<SupervisorAccountIcon />} aria-label="all" onClick={() =>
                                context.activePerson.set(prevSelectedPerson)
                            } />
                        </Tabs>
                    </Paper>

                    <Paper square>
                        <Tabs
                            value={activityValue}
                            onChange={handleActivityChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon tabs example"
                        >
                            <Tab label="Vis alle" icon={<SelectAllIcon />} aria-label="all" onClick={() =>
                                context.chosenActivity.set("all")
                            } />
                            <Tab icon={<DirectionsWalkIcon />} aria-label="byfoot" onClick={() =>
                                context.chosenActivity.set("byfoot")
                            } />
                            <Tab icon={<DirectionsBikeIcon />} aria-label="bike" onClick={() =>
                                context.chosenActivity.set("bike")
                            } />
                            <Tab icon={<AcUnitIcon />} aria-label="winter" onClick={() =>
                                context.chosenActivity.set("winter")
                            } />
                        </Tabs>
                    </Paper>

                    <Button variant="contained" onClick={() =>
                        setDateValue(DISABLEDTAB)
                    } >
                        Time slider
                    </Button>

                    <Paper square>
                        <Tabs
                            value={dateValue}
                            onChange={handleDateChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="icon tabs example"
                        >
                            <Tab label="Siste 30 dager" icon={<ViewComfyIcon />} aria-label="prev30" onClick={() =>
                                setLast30Days(false)
                            } />
                            <Tab label="Forrige uke" icon={<ViewCarouselIcon />} aria-label="prevweek" onClick={() =>
                                setPreviousWeek()
                            } />
                            <Tab label="Denne uken" icon={<ViewWeekIcon />} aria-label="currentweek" onClick={() =>
                                setCurrentWeek()
                            } />
                        </Tabs>
                    </Paper>


                </div>
            }
        </div>
    );
}