import React, { useContext } from 'react';
import { AppContext } from '../../state/context';

import { IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';

type CityProp = {
    city: string,
}

export default function ZoomToButton(props: CityProp) {
    const context = useContext(AppContext);
    const config = require("./cities_wm.json");
    const city = props.city;

    // function setProps

    const onClickCity = () => {
        const mapView = context.mapView.value;
        const center =  mapView.center;

        center.x = config[city].x;
        center.y = config[city].y;
        mapView.center = center;
        mapView.zoom = config[city].zoom;
    }

    const mystyles = {
        // width: '10px',
        height: '50px'
    } as React.CSSProperties;

    return (
        <div>
            <Tooltip title={`Zoom til ${props.city}`}>
                <IconButton component="div" size="medium" onClick={() => onClickCity()} >
                    <img src={config[city].logo} style={mystyles}  />
                </IconButton >
            </Tooltip>
        </div>
    );
}
