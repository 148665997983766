
/* External Components */
import React, { useEffect, useContext, useRef, useState } from 'react';
// import { loadCss, loadModules } from 'esri-loader';
import "./MapViewStyle.css";

import { AppContext } from "../../state/context";
import { Trip } from '../../model/interfaces';
import { getTrip } from '../../services/tripService';

import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
import Layer from "@arcgis/core/layers/Layer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

import Expand from "@arcgis/core/widgets/Expand";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import LayerList from "@arcgis/core/widgets/LayerList";
import Search from "@arcgis/core/widgets/Search";
import Graphic from "@arcgis/core/Graphic";
import TopFeaturesQuery from "@arcgis/core/rest/support/TopFeaturesQuery";
import TopFilter from "@arcgis/core/rest/support/TopFilter";

import LocalBasemapsSource from "@arcgis/core/widgets/BasemapGallery/support/LocalBasemapsSource"
import { GraatoneVector, GraatoneTerrengVector, OSM, Basis, Mork, GlobalGraatone, GlobalMork } from './Basemaps';

let mapView: MapView;
let geoExplorerLayer: FeatureLayer;

const MainMapView = () => {

    const context = useContext(AppContext);
    const [mapIsLoaded, setMapIsLoaded] = useState(false);

    const mapDiv = useRef(null);

    useEffect(() => {
        if (mapDiv.current && context.user.value) {
            var webmap = new WebMap({
                portalItem: {
                    id: "253b1ba1fb3840499d2fefc3e26908d1"
                }
            });

            mapView = new MapView({ map: webmap, container: "mapDiv" });

            mapView.when((mapView: any) => {

                geoExplorerLayer = mapView.map.findLayerById("GeoExplorer_8755") as FeatureLayer;

                context.mapView.set(mapView);
                setMapIsLoaded(true);
                getAllAvailablePerson();

                const layer = mapView.map.findLayerById("GeoExplorer_8755");
                getLayerStats(layer);
                getLayerTopList(layer);

                const url = 'https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoExplorer/FeatureServer/0'

                mapView.on("click", (event: any) => {

                    const opts = {
                        include: layer
                    }
                    mapView.hitTest(event, opts).then((response: any) => {

                        if (response.results.length) {

                            var result = response.results[0];
                            var geometry = result.graphic.geometry;

                            var symbol = {
                                type: "simple-line",
                                color: "#575757",
                                width: "4px",
                                style: "solid"
                            };

                            var graphic = new Graphic({ geometry: geometry, symbol: symbol });

                            mapView.graphics.removeAll();
                            mapView.graphics.add(graphic);

                            const oid = result.graphic.attributes.OBJECTID

                            getTrip(url, oid).then((response: Trip) => {
                                context.trip.set(response);
                            })

                        }
                        else {
                            context.trip.set(null);
                            mapView.graphics.removeAll();
                        }
                    });
                });

                // ------------------------- Add widgets

                // ------------- Search

                const searchWidget = new Search({
                    view: mapView,
                    // container: "map"
                });

                const localSource = new LocalBasemapsSource({
                    basemaps: [
                        GraatoneVector(),
                        GraatoneTerrengVector(),
                        // Basis(),
                        OSM(),
                        Mork(),
                        GlobalGraatone(),
                        GlobalMork()
                    ]
                })

                const basemapGallery = new BasemapGallery({
                    source: localSource,
                    view: mapView,
                    container: mapView
                });

                const expandBaseMap = new Expand({
                    expandIconClass: "esri-icon-basemap",
                    expandTooltip: "Bakgrunnskart",
                    view: mapView,
                    // container: mapView,
                    content: basemapGallery,
                    expanded: false,
                    group: "top-left",
                    mode: "floating"
                });

                // ------------------- Layer List

                var layerList = new LayerList({
                    view: mapView,
                    listItemCreatedFunction: function (event: any) {
                        const item = event.item;
                        if (item.layer.type !== "group") {
                            // don't show legend twice
                            item.panel = {
                                content: "legend",
                                open: true
                            };
                        }
                    }
                });

                const expandLayerList = new Expand({
                    expandIconClass: "esri-icon-layer-list",
                    expandTooltip: "Kartlagsliste",
                    view: mapView,
                    content: layerList,
                    expanded: false,
                    group: "top-left"
                });

                mapView.ui.add(expandBaseMap, { position: "top-left", index: 0 });
                mapView.ui.add(expandLayerList, { position: "top-left", index: 1 });

                var query = getDefinitionQuery();
                layer.definitionExpression = query;
            })
        }
    }, []);

    function getDefinitionQuery() {
        const queries = [];

        // Person query
        if (context.activePerson.value !== "all") {
            queries.push(`person = '${context.activePerson.value}'`);
        }

        // Activity query
        if (context.chosenActivity) {
            if (context.chosenActivity.value === "bike") {
                queries.push(`activity_type in ('ride', 'ebikeride')`);
            }
            else if (context.chosenActivity.value === "byfoot") {
                queries.push(`activity_type in ('walk', 'hike', 'run', 'rockclimbing')`);
            }
            else if (context.chosenActivity.value === "winter") {
                queries.push(`activity_type in ('nordicski', 'backcountryski', 'alpineski', 'snowboard', 'iceskate')`);
            }
        }
        else {
            context.chosenActivity.set("all");
        }

        // Time query
        if (context.dateDefQuery.value !== null) {
            queries.push(context.dateDefQuery.value);
        }

        let query;

        if (queries.length === 0) {
            query = "1=1";
        } else {
            query = queries.join(" AND ");
        }

        console.log(query);
        return query;
    }

    const getLayerTopList = (layer: any) => {
        const query = new TopFeaturesQuery({
            where: layer.definitionExpression,
            outFields: ["*"],
            returnGeometry: true,
            topFilter: new TopFilter({
                topCount: 3,
                groupByFields: ["source"],
                orderByFields: ["km DESC"]
            })
        });
        layer.queryTopFeatures(query)
            .then(function (res: any) {
                context.topStats.set(res.features);
            });
    }

    const getLayerStats = (layer: any) => {
        if (context.statGroupField.value !== "tripinfo") {
            context.lenStats.set([]);

            const query = layer.createQuery();
            query.outStatistics = {
                statisticType: "sum",
                onStatisticField: "km",
                outStatisticFieldName: "SumLength"
            }
            query.groupByFieldsForStatistics = [context.statGroupField.value];
            query.orderByFields = ["SumLength desc"]

            layer.queryFeatures(query).then((res: any) => {
                context.lenStats.set(res.features);
            });
        }
    }

    function updateMap() {
        if (mapView) {

            if (geoExplorerLayer == null) {
                geoExplorerLayer = mapView.map.findLayerById("GeoExplorer_8755") as FeatureLayer;
                return
            }

            var query = getDefinitionQuery();

            geoExplorerLayer.definitionExpression = query;
            getLayerStats(geoExplorerLayer);
            getLayerTopList(geoExplorerLayer);
        }
    }

    useEffect(() => {
        updateMap();
    }, [context.activePerson.value, context.dateDefQuery.value, context.infoMode.value, context.statGroupField.value, context.chosenActivity.value]);

    const getAllAvailablePerson = () => {
        if (mapView) {

            if (geoExplorerLayer == null) {
                geoExplorerLayer = mapView.map.findLayerById("GeoExplorer_8755") as FeatureLayer;
                return
            }

            var query = geoExplorerLayer.createQuery();

            query.where = "person is not null";
            query.orderByFields = ["person"];
            query.returnGeometry = false;
            query.returnDistinctValues = true;
            query.outFields = ["person"];

            let person_list: string[] = [];

            geoExplorerLayer.queryFeatures(query).then((results: any) => {
                results.features.forEach((element: any) => {
                    let person: string = element.attributes.person;
                    person_list.push(person);
                });
            });
            context.uniquePersonList.set(person_list);
        }
    }
    return (
        <div>
            <div className="mapContainer shadow" id="mapDiv" ref={mapDiv}></div>
        </div>
    )

}

export default MainMapView;
