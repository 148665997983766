import React, { useState, useContext } from 'react';
import { AppContext } from "../../state/context";
import "./StatsStyle.css";
import { AppBar, Tabs, Tab } from '@material-ui/core';

export default function Stats() {
  const context = useContext(AppContext);
  const [maximized, setMaximized] = useState(true);

  return (
    <div style={{ display: maximized ? "" : "none" }} className="">
      <div className="p-3">
        <div className="text-xl my-2">
          Toppliste
        </div>
        <div>
          {context.lenStats.value.map((feature: any, index: number) => {
            return <div className="flex">
              <div className="pr-2">
                {index + 1}.
              </div>
              <div>
                {feature.attributes[context.statGroupField.value]}
              </div>
              <div className="ml-auto">
                {Math.floor(feature.attributes.SumLength)}km
              </div>
            </div>
          })}
        </div>

      </div>
      <AppBar position="static" color="secondary">
        <Tabs
          value={context.statGroupField.value}
          onChange={(event, value) => {
            context.statGroupField.set(value);
            setMaximized(true);
          }}
          aria-label="simple tabs example s">

          <Tab value="person" label="Person" />
          <Tab value="activity_type" label="Akivitet" />

        </Tabs>
      </AppBar>
    </div>
  );
}