
/* External Components */
import React, { useContext } from 'react';
import './HeaderStyle.css';
import { AppContext } from "../../state/context";
import { isMobile } from "react-device-detect";
import Syncer from '../../components/Syncer/Syncer';
import Sidebar from '../Sidebar/Sidebar';
import Filters from '../Filters/Filters';
import CityPaper from '../CityPaper/CityPaper';


const Header = () => {
    const context = useContext(AppContext);

    return (
        <div className="client-header flex items-center">
            <Sidebar />
            <div className="px-8">
                <strong>{context.user.value.fullName}</strong>
            </div>
            {!isMobile &&
                <Syncer />
            }
            <div className="px-0.2">
                <CityPaper/> 
            </div>
            <Filters />
        </div>
    )

}

export default Header;
