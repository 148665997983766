
import Basemap from '@arcgis/core/Basemap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import VectorTileLayer from '@arcgis/core/layers/VectorTileLayer';


export const GraatoneVector = (): Basemap => {
   
    return new Basemap({
        baseLayers: [
            new VectorTileLayer({
                url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatone_WM/VectorTileServer'
            })
        ],
        thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/gr%C3%A5tone.png',
        id: "graatone",
        title: "Gråtone"
    });
}

export const GraatoneTerrengVector = (): Basemap => {
   
    return new Basemap({
        baseLayers: [
            new VectorTileLayer({
                url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatoneTerreng_WM/VectorTileServer'
            })
        ],
        thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/gr%C3%A5tone%20terreng.png',
        id: "graatoneTerreng",
        title: "Gråtone Terreng"
    });
}

export const Basis = (): Basemap => {
   
    return new Basemap({
        baseLayers: [
            new VectorTileLayer({
                url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasis_WM/VectorTileServer'
            })
        ],
        thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/basis.png',
        id: "basis",
        title: "Basis"
    });
}

export const OSM = (): Basemap => {
   
    return new Basemap({
        baseLayers: [
            new VectorTileLayer({
                url: 'https://basemaps.arcgis.com/arcgis/rest/services/OpenStreetMap_v2/VectorTileServer/'
            })
        ],
        thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/osm.png',
        id: "osm",
        title: "Open Street Map"
    });
}

export const Mork = (): Basemap => {
   
    return new Basemap({
        baseLayers: [
            new VectorTileLayer({
                url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheKanvasMork_WM/VectorTileServer'
            })
        ],
        thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/kanvas%20m%C3%B8rk.png',
        id: "mork",
        title: "Mørk Kanvas"
    });
}

export const GlobalGraatone = (): Basemap => {
   
    return new Basemap({
        portalItem: {
          id: "ee8678f599f64ec0a8ffbfd5c429c896"
        },
        id: "graaesri",
        title: "Global Gråtone (Esri)"
    });
}

export const GlobalMork = (): Basemap => {
   
    return new Basemap({
        portalItem: {
          id: "59548997ef4649aabe3b320a113d4097"
        },
        id: "morkesri",
        title: "Global Mørk Kanvas (Esri)"
    });
}




    // function createLocalBasemapSource(){

    //     loadModules([
    //         'esri/Basemap',
    //         "esri/layers/VectorTileLayer",
    //         'esri/layers/TileLayer',
    //         "esri/widgets/BasemapGallery/support/LocalBasemapsSource"
    //     ])
    //         .then(([Basemap, VectorTileLayer, TileLayer, LocalBasemapsSource]) => {

    //             const graatone = new Basemap({
    //                 baseLayers: [
    //                     new VectorTileLayer({
    //                         url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatone_WM/VectorTileServer'
    //                     })
    //                 ],
    //                 thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/gr%C3%A5tone.png',
    //                 id: "vectortile0",
    //                 title: "Gråtone"
    //             });

    //             const graatone_terreng = new Basemap({
    //                 baseLayers: [
    //                     new VectorTileLayer({
    //                         url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatoneTerreng_WM/VectorTileServer'
    //                     })
    //                 ],
    //                 thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/gr%C3%A5tone%20terreng.png',
    //                 id: "vectortile1",
    //                 title: "Gråtone Terreng"
    //             });

    //             const basis = new Basemap({
    //                 baseLayers: [
    //                     new VectorTileLayer({
    //                         url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasis_WM/VectorTileServer'
    //                     })
    //                 ],
    //                 thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/basis.png',
    //                 id: "vectortile2",
    //                 title: "Basis"
    //             });

    //             const basis_terreng = new Basemap({
    //                 baseLayers: [
    //                     new VectorTileLayer({
    //                         url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasisTerreng_WM/VectorTileServer'
    //                     })
    //                 ],
    //                 thumbnailUrl: 'https://labs.geodataonline.no/bakgrunnskart/images/basis%20terreng.png',
    //                 id: "vectortile3",
    //                 title: "Basis"
    //             });

    //             // const vectorGraatone = new TileLayer({
    //             //     url: 'https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheBasisTerreng_WM/VectorTileServer'
    //             // });
    //             // //const labelReferenceLayer = new VectorTileLayer({
    //             // //    url: 'https://geodata.maps.arcgis.com/sharing/rest/content/items/625aea31e122434fb1b11cdc0f077415/resources/styles/root.json'
    //             // //});
    //             // const basemap = new Basemap({
    //             //     baseLayers: [
    //             //         vectorGraatone
    //             //     ],
    //             //     thumbnailUrl: '/images/thumbnail_graa.png',
    //             //     //referenceLayers: [
    //             //     //    labelReferenceLayer
    //             //     //],
    //             //     id: "graatone",
    //             //     title: "Gråtone"
    //             // });

    //             return new LocalBasemapsSource({
    //                 basemaps: [
    //                     graatone,
    //                     basis,
    //                     basis_terreng
    //                 ]
    //             })

    //             // return new LocalBasemapsSource({
    //             //     basemaps: [
    //             //         graatone,
    //             //         graatone_terreng,
    //             //         basis,
    //             //         osm,
    //             //         osm_graa,
    //             //         osm_basis
    //             //     ]
    //             // })
    //         })
    // }
