
import esriRequest from "@arcgis/core/request";
// import QueryTask from "@arcgis/core/tasks/QueryTask";
import * as query from "@arcgis/core/rest/query";
import Query from "@arcgis/core/rest/support/Query";

export const getHeightProfile = (geometry: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const url = `https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheTerreng/ImageServer/getSamples`;

        esriRequest(url, {
            query: {
                returnFirstValueOnly: false,
                geometry: geometry,
                geometryType: "esriGeometryPolyline",
                sampleCount: 150,
                f: "json"
            }
        }).then((res: any) => {
            resolve(res);
        });
    });
}

export const getTrip = (url: string, objectid: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        const queryObject = new Query({
            where: `OBJECTID=${objectid}`,
            outFields: ["*"],
            returnGeometry: true,
            outSpatialReference: { wkid: 25833 }
        });

        let queryUrl = url;

        query
            .executeQueryJSON(queryUrl, queryObject)
            .then((result: any) => {
                resolve(result.features[0])
            });

    });
}
