import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../state/context";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import "./SyncerStyle.css";
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export default function FormDialog() {
      const context = useContext(AppContext);

      const [open, setOpen] = useState(false);
      const [alertOpen, setAlertOpen] = useState(false);
      const [alertErrorOpen, setAlertErrorOpen] = useState(false);
      const [loading, setLoading] = useState(false);

      //Strava Credentials
      let clientID = "41794";
      let clientSecret = "6f9c944582020c61f8e8bc0f3396863d2537bc89";
      const authUrl = `http://www.strava.com/oauth/authorize?client_id=${clientID}&response_type=code&redirect_uri=https://geoexplorer.teamy-dev-k8s10.geodataonline.no//&approval_prompt=force&scope=activity:read_all`
      const serviceUrl = "https://teamy-dev-k8s10.geodataonline.no/arcgis/rest/services/teamy-dev/strava-to-agol/GPServer/strava-to-agol-task";
      
      useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            
            if (urlParams.get("code")) {
                  window.history.pushState("", "", "/");
                  const code = urlParams.get("code");
                  const tokenUrl = `https://www.strava.com/oauth/token?client_id=${clientID}&client_secret=${clientSecret}&code=${code}`;

                  fetch(tokenUrl, {
                        method: 'POST'
                  })
                  .then((res: any) => {
                       res.json().then((result: any) => {
                              localStorage.setItem("stravaToken", result.refresh_token);
                              handleSubmit();
                       })
                  })
            }
      }, [])
      
      const handleClickOpen = () => {
            if (localStorage.getItem("stravaToken")) {
                  handleSubmit();
            } else {
                  setOpen(true);
            }
      };
      
      const handleClose = () => {
            setOpen(false);
      };
      
      const handleSubmit = () => {
            if (localStorage.getItem("stravaToken")) {
                  setLoading(true);
                  // Call token api
                  const callRefresh = `https://www.strava.com/oauth/token?client_id=${clientID}&client_secret=${clientSecret}&refresh_token=${localStorage.getItem("stravaToken")}&grant_type=refresh_token`
                  fetch(callRefresh, {
                        method: 'POST'
                  })
                  .then((res: any) => {
                        res.json().then((result: any) => {
                              // Use resulting token, run GP-tool      
                              const stravaToken = result.access_token;
                              
                              fetch(`${serviceUrl}/submitJob?person_name=${context.user.value.fullName}&strava_token=${stravaToken}`, {
                                    method: 'POST'
                              })
                              .then((res: any) => {
                                   res.json().then((result: any) => {
                                          // Get jobId, ask for status
                                          const jobId = result.jobId;

                                          let interval = setInterval(() => {
                                                fetch(`${serviceUrl}/jobs/${jobId}`, {
                                                      method: 'POST'
                                                })
                                                .then((res: any) => {
                                                     res.json().then((res: any) => {
                                                      if (res.jobStatus === 'esriJobSucceeded') {
                                                            clearInterval(interval);
                                                            setLoading(false);

                                                            // Fetch number of added trips
                                                            fetch(`${serviceUrl}/jobs/${jobId}/results/n_trips`, {
                                                                  method: 'POST'
                                                            })
                                                            .then((res: any) => {
                                                                 res.json().then((res: any) => {
                                                                       setAlertOpen(res.value);
                                                                  });
                                                            });
                                                      } else if (res.jobStatus === 'esriJobFailed') {
                                                            clearInterval(interval);
                                                            setLoading(false);
                                                            setAlertErrorOpen(true);
                                                      }
                                                })
                                          });
                                          }, 3000);
                                   })
                              }) 
                       })
                  }) 
            } else {
                  window.location.href = authUrl;
            }
      }

      const getSyncButtonContent = () => {
            if (localStorage.getItem("stravaToken")) {
                  if (loading) {
                        return <React.Fragment>
                              Synkroniserer
                              <CircularProgress className="spinner" size={20}/>
                        </React.Fragment>
                  } else {
                        return "Synkroniser";
                  }
            } else {
                  return "Autoriser";
            }
      }

  return (
    <div>
      <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={alertOpen}
            autoHideDuration={10000}
            onClose={() => setAlertOpen(false)}
            ContentProps={{
            'aria-describedby': 'message-id',
            }}
      >
            <Alert variant="filled" severity="success">Synkronisering fullført. {alertOpen} aktiviteter ble lagt til.</Alert>
      </Snackbar>
      <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={alertErrorOpen}
            autoHideDuration={10000}
            onClose={() => setAlertErrorOpen(false)}
            ContentProps={{
            'aria-describedby': 'message-id',
            }}
      >
            <Alert variant="filled" severity="error">Synkronisering av nye aktiviteter feilet.</Alert>
      </Snackbar>
      <Button disabled={loading} className="btn-sync" variant="contained" color="primary" onClick={() => {handleClickOpen()}}>
            {getSyncButtonContent()}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Synkroniser</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
                  localStorage.getItem("stravaToken") ?
                  <div>Strava-bruker autorisert. Klikk under for å starte synkroniseringen.</div> : 
                  <div>Strava-bruker ikke autorisert. Klikk under for å sendes til autoriseringssiden.</div>
            }
          </DialogContentText>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Token"
            // type="email"
            fullWidth
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Avbryt
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {localStorage.getItem("stravaToken") ? "Synkroniser" : "Autoriser"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}