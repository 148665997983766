
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { AppContext } from "../../state/context";
import { Trip } from '../../model/interfaces';
import { getHeightProfile } from '../../services/tripService';
import "./HeightProfileStyle.css";
import ElevationProfile from "@arcgis/core/widgets/ElevationProfile";
import ElevationLayer from "@arcgis/core/layers/ElevationLayer";

let elevationProfile: ElevationProfile;

const HeightProfile = (props: {trip: Trip}) => {

      const context = useContext(AppContext);
      const [heights, setHeights] = useState([]);
      const [currentHeight, setCurrentHeight] = useState(null);
      
      useEffect(() => {
            const visibleElements = {
                  legend: false,
                  chart: true,
                  clearButton: false,
                  settingsButton: false,
                  sketchButton: false,
                  selectButton: false,
                  uniformChartScalingToggle: true
            }
            const elevLayer = new ElevationLayer({
                  url: "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheTerreng/ImageServer/"
                });
                elevationProfile = new ElevationProfile({
                  view: context.mapView.value,
                  container: "widgetContainer",
                  input: props.trip,
                  profiles: [{
                        type: "query", // autocasts as new ElevationProfileLineQuery(),
                        source: elevLayer
                  }],
                  visibleElements
            });
      }, [])

      useEffect(() => {
            // getHeightProfile(props.trip.geometry).then((result) => {
            //       try {
            //             setHeights(result.data.samples);
            //       }
            //       catch(e) {
            //             // let e:Error= _e;
            //             console.log((e as Error).message);
            //       }
            // })
            elevationProfile.input = props.trip as __esri.Graphic;
      }, [context.trip.value]);

      if (!heights) {
            return <div className="widget">Laster høydeprofil..</div>;
      }

      const data = heights.map((sample: any, index: number) => {
                  return {x: index, y: Math.floor(sample.value), location: sample.location};
            });

    return (
      <div className="widget flex flex-col items-center py-8 w-full">
            {currentHeight && <div className="absolute">{currentHeight} moh</div>}
            <div id="widgetContainer"></div>
            {/* <ResponsiveContainer width="100%" height={100}>
                  <AreaChart data={data}>
                        <YAxis domain={['dataMin', 'dataMax']}/>
                        <Area type="monotone" fillOpacity="1" dataKey="y" stroke="rgba(0,114,206,1)" fill="rgba(0,114,206,1)" />
                  </AreaChart>
            </ResponsiveContainer> */}
      </div>
    );
    
}
  
  export default HeightProfile;
