import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from "../../state/context";
import "./InfoBoxStyle.css";
import TripView from '../Trip/Trip';
import Stats from "../Stats/Stats";
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { isMobile } from "react-device-detect";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TopList from '../TopList/TopList';

export default function InfoBox() {
  const context = useContext(AppContext);
  const [maximized, setMaximized] = useState(true);

  useEffect(() => {
    if (context.trip.value) {
      setMaximized(true);
    }
  }, [context.trip.value]);

  return (
    <div className={isMobile ? "stats-container-mobile" : "stats-container"}>
      {maximized &&
        <div style={{ display: maximized ? "" : "none" }} className="">
          {(context.infoMode.value === "tripinfo") && <TripView />}
          {(context.infoMode.value === "toplist") && <TopList />}
          {(context.infoMode.value === "stats") && <Stats />}
        </div>
      }
      <AppBar position="static">
        <Tabs
          value={context.infoMode.value}
          onChange={(event, value) => {
            context.infoMode.set(value);
            setMaximized(true);
          }}
          aria-label="simple tabs example s">

          <Tab value="tripinfo" label="Trip Info" />
          {/* <Tab value="person" label="Person"/> */}
          <Tab value="toplist" label="Toppliste" />
          <Tab value="stats" label="Statistikk" />

        </Tabs>
      </AppBar>
      <div onClick={() => setMaximized(!maximized)} className="stats-minimizer">
        {maximized ?
          <KeyboardArrowDownIcon />
          :
          <KeyboardArrowUpIcon />
        }
      </div>
    </div>
  );
}