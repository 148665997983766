import React, { useState } from 'react';
import { isMobile } from "react-device-detect";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import "./CityPaperStyle.css";
import ZoomToButton from '../ZoomToButton/ZoomToButton';

export default function CityPaper() {
    const [visible, setVisible] = useState(false);

    const getMobileCities = () => {
        return <React.Fragment>
            <ZoomToButton
                city="Sandnes"
            />
            <ZoomToButton
                city="Oslo"
            />
            <ZoomToButton
                city="Trondheim"
            />
        </React.Fragment>
    }

    return (
        <div className="ml-auto flex flex-row">
            {!isMobile &&
                <div className="flex flex-row pl-4">
                    {getMobileCities()}
                </div>
            }
            <div className="cursor-pointer pr-8 m-auto" onClick={() => setVisible(!visible)}>
                <ArrowDropDownIcon
                    className="mr-2"
                    style={{ fontSize: 30 }}
                />
            </div>
            {visible &&
                <div className={`flex flex-row paper-container${isMobile ? "-mobile" : ""} `}>
                    {isMobile && getMobileCities()}
                    <ZoomToButton
                        city="Rogaland"
                    />
                    <ZoomToButton
                        city="Øyer"
                    />
                    <ZoomToButton
                        city="Sarpsborg"
                    />
                    <ZoomToButton
                        city="Bø"
                    />
                </div>
            }
        </div>
    );
}