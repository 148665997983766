import React, { useState, useContext } from 'react';
import { AppContext } from "../../state/context";
// import "./StatsStyle.css";
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Graphic from '@arcgis/core/Graphic';

export default function TopList() {
      const context = useContext(AppContext);
      const [maximized, setMaximized] = useState(true);

      const onClickTrip = (feature: any) => {
            const mapView = context.mapView.value;

            var geometry = feature.geometry;

            var symbol = {
                  type: "simple-line",
                  color: "#575757",
                  width: "4px",
                  style: "solid"
            };

            var graphic = new Graphic({ geometry: geometry, symbol: symbol });

            mapView.graphics.removeAll();
            mapView.graphics.add(graphic);
            mapView.extent = graphic.geometry.extent;

            context.trip.set(feature);
            context.infoMode.set("tripinfo");
      }

      return (
            <div style={{ display: maximized ? "" : "none" }} className="">
                  <div className="p-3">
                        <div className="text-xl my-2">
                              Toppliste
        </div>
                        <div>
                              {context.topStats.value.map((feature: any, index: number) => {
                                    return <div className="cursor-pointer flex" onClick={() => {
                                          onClickTrip(feature)
                                    }}>
                                          <div className="pr-2">
                                                {index + 1}.
              </div>
                                          <div>
                                                {feature.attributes.title}
                                          </div>
                                          <div className="ml-auto">
                                                {Math.floor(feature.attributes.km)}km
              </div>
                                    </div>
                              })}
                        </div>

                  </div>
                  {/* <AppBar position="static" color="secondary">
        <Tabs
          value={context.statGroupField.value}
          onChange={(event, value) => {
            context.statGroupField.set(value);
            setMaximized(true);
          }}
          aria-label="simple tabs example s">

          <Tab value="person" label="Person" />
          <Tab value="activity_type" label="Akivitet" />

        </Tabs>
      </AppBar> */}
            </div>
      );
}