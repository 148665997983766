
/* External Components */
import React, { useContext } from 'react';

import { AppContext } from "../../state/context";
import { Trip } from '../../model/interfaces';

import "./TripStyle.css";

import moment from 'moment';
import 'moment/locale/nb';
import "react-image-gallery/styles/css/image-gallery.css";

import HeightProfile from '../HeightProfile/HeightProfile';

import Grid, { GridSpacing } from '@material-ui/core/Grid';

import { Typography } from '@material-ui/core';
import StravaIcon from '../../assets/strava.png';

const TripView = () => {
  const context = useContext(AppContext);

  const trip: Trip = context.trip ? context.trip.value : null;
  moment.locale('nb');
  const [spacing, setSpacing] = React.useState<GridSpacing>(2);
  return (
    <div className="flex flex-col w-full">
      {trip &&
        <div className="flex flex-col items-center relative">

          <div className="text-3xl p-4 flex flex-row items-center" >
            {trip.attributes.title}
          </div>
          <a href={"https://www.strava.com/activities/" + trip.attributes.source_id} target="_blank"><img src={StravaIcon}/></a>

          <div className="flex flex-row p-4 text-xl items-center">
          </div>
          <Grid container justify="center" spacing={spacing}>
            <Grid key={"1"} item>
              {/* <Paper className={classes.paper} > */}
              <Typography gutterBottom variant="subtitle1">
                Person
                </Typography>
              {/* <Paper /> */}
            </Grid>
            <Grid key={"2"} item>
              <Typography gutterBottom variant="subtitle1">
                {trip.attributes.person}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="center" spacing={spacing}>
            <Grid key={"1"} item>
              {/* <Paper className={classes.paper} > */}
              <Typography gutterBottom variant="subtitle1">
                Distanse
                </Typography>
              {/* <Paper /> */}
            </Grid>
            <Grid key={"2"} item>
              <Typography gutterBottom variant="subtitle1">
                {Math.round((trip.attributes.km + Number.EPSILON) * 100) / 100} km
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="center" spacing={spacing}>
            <Grid key={"1"} item>
              {/* <Paper className={classes.paper} > */}
              <Typography gutterBottom variant="subtitle1">
                Stigning
                </Typography>
              {/* <Paper /> */}
            </Grid>
            <Grid key={"2"} item>
              <Typography gutterBottom variant="subtitle1">
                {Math.round((trip.attributes.elevation + Number.EPSILON) * 100) / 100} m
              </Typography>
            </Grid>
          </Grid>

          <Grid container justify="center" spacing={spacing}>
            <Grid key={"1"} item>
              <Typography gutterBottom variant="subtitle1">
                Type
                </Typography>
            </Grid>
            <Grid key={"2"} item>
              <Typography gutterBottom variant="subtitle1">
                {trip.attributes.activity_type}
              </Typography>
            </Grid>
          </Grid>


          {/* <div className="trip-desc text-justify py-5" dangerouslySetInnerHTML={{ __html: trip.attributes.activity_type }}>
            </div> */}
          <HeightProfile trip={trip} />

        </div>
      }
      {
        !trip &&
        <div className="flex flex-col items-center p-8 relative">
          Ingen tur valgt
        </div>
      }
    </div >
  )

}

export default TripView;
