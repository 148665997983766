
/* External Components */
import React, { useState, useContext } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import { AppContext } from "../../state/context";
import { Drawer } from '@material-ui/core';
import { isMobile } from "react-device-detect";
import Syncer from "../Syncer/Syncer";
// import TripView from '../Trip/Trip';

export default function Sidebar() {

    const context = useContext(AppContext);
    const [open, setOpen] = useState(false);

    const renderList = () => (
        <div>
            <List>
                <ListItem button key={context.user.value.fullName}>
                    <ListItemIcon><PersonIcon /></ListItemIcon>
                    <ListItemText primary={context.user.value.fullName} />
                </ListItem>
                {isMobile &&
                    <ListItem button key={"sync"}>
                        <Syncer/>
                    </ListItem>
                }
            </List>
            <Divider />
            <List>
                <ListItem button key={"all"} onClick={() => context.activePerson.set("all")} selected={context.activePerson.value === "all"}>
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary={"Vis alle"} />
                </ListItem>
                { context.uniquePersonList.value.map((text: string) => (
                    <ListItem button key={text} onClick={() => context.activePerson.set(text)} selected={context.activePerson.value === text}>
                        <ListItemIcon><PersonIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
                <ListItem button key={"add"}>
                    <ListItemIcon><AddIcon /></ListItemIcon>
                    <ListItemText primary={"Legg til flere"} />
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className="px-8 cursor-pointer">
            <MenuIcon onClick={() => setOpen(true)} />
            <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
                {renderList()}
            </Drawer>
        </div>
    );
}