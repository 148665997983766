import React, { useState, useEffect } from "react";

import './App.css';
import MainMapView from './components/MapView/MapView';
import Header from './components/Header/Header';
import { AppContext } from "./state/context";
import InfoBox from "./components/InfoBox/InfoBox";

import esriConfig from '@arcgis/core/config.js';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import Portal from '@arcgis/core/portal/Portal';

function App() {

  const [mapView, setMapView] = useState(null);
  const [trip, setTrip] = useState(null);
  const [portal, setPortal] = useState(null);
  const [user, setUser] = useState(null);
  const [uniquePersonList, setUniquePersonList] = useState([]);
  const [activePerson, setActivePerson] = useState(null);
  const [infoMode, setInfoMode] = useState("tripinfo");
  const [statGroupField, setStatGroupField] = useState("person");
  const [lenStats, setLenStats] = useState([]);
  const [topStats, setTopStats] = useState([]);
  const [chosenActivity, setChosenActivity] = useState("all");
  const [dateDefQuery, setdateDefQuery] = useState(null);

  const store = {
    mapView: {value: mapView, set: setMapView},
    trip: { value: trip, set: setTrip },
    portal: { value: portal, set: setPortal },
    user: { value: user, set: setUser },
    uniquePersonList: { value: uniquePersonList, set: setUniquePersonList },
    activePerson: { value: activePerson, set: setActivePerson },
    infoMode: { value: infoMode, set: setInfoMode },
    statGroupField: { value: statGroupField, set: setStatGroupField },
    lenStats: { value: lenStats, set: setLenStats },
    topStats: { value: topStats, set: setTopStats },
    chosenActivity: { value: chosenActivity, set: setChosenActivity },
    dateDefQuery: { value: dateDefQuery, set: setdateDefQuery }
  }

  useEffect(() => {

    const portalUrl = "https://geodata.maps.arcgis.com/"
    esriConfig.portalUrl = portalUrl;

    var info = new OAuthInfo({
      appId: "TIYCdQJH38DpwiZS",
      // Uncomment the next line and update if using your own portal
      portalUrl: esriConfig.portalUrl,
      // Uncomment the next line to prevent the user's signed in state from being shared
      // with other apps on the same domain with the same authNamespace value.
      //authNamespace: "portal_oauth_inline",
      popup: false
    });

    const portal = new Portal({
      url: portalUrl
    });

    IdentityManager.registerOAuthInfos([info]);
    IdentityManager.getCredential(esriConfig.portalUrl + "/sharing").then((res: any) => {
      portal.load().then(() => {
        var queryParameters = {
          query: "username:" + portal.user.username
        };

        portal.queryUsers(queryParameters).then((queryResults: any) => {
          var person = queryResults.results[0];
          var name = person.fullName;
          setUser(person);
          setActivePerson(name);
        });
      })
    })
  }, []);

  return (
    <AppContext.Provider value={store}>
      {user && activePerson &&
        <div className="App">
          <MainMapView />
          <Header />
          <InfoBox />
        </div>
      }
    </AppContext.Provider>
  );
}

export default App;
